import React from 'react'

import { withIntl } from '../i18n'

import Layout from '../components/Layout'
import ContentBlock from '../components/ContentBlock'

const NotFoundPage = () => (
  <Layout>
    <ContentBlock content={['error.404.text']} />
  </Layout>
)

export default withIntl(NotFoundPage)
